<template>
  <div class="container-fluid mt--6">
    <div class="row">
      <div class="col-sm-12">
        <div class="card mb-3">
          <div class="card-header position-relative">
            <h3 class="mb-0 text-center">Price Tariff</h3>
          </div>

          <div class="card-body price-tariff">
            <h5>Personal Account Price & Bank Fee Standard</h5>

            <table style="width: 100%">
              <tr>
                <td colspan="2">
                  <strong>Account Opening</strong>
                </td>
              </tr>
              <tr>
                <td>KYC Compliance &amp; Admin proceed for GBP account</td>
                <td>
                  <span>Free</span>
                </td>
              </tr>
              <tr>
                <td>KYC Compliance &amp; Admin proceed for EUR account</td>
                <td>
                  <span>Free</span>
                </td>
              </tr>

              <tr>
                <td>Person Debit Card</td>
                <td><span>£10</span></td>
              </tr>
         
              <tr>
                <td class="pt-4"><strong>Monthly Charge</strong></td>
                <td></td>
              </tr>
              <tr>
                <td>Monthly Service Charge on GBP account</td>
                <td>
                  <span>£9.99</span>
                </td>
              </tr>
              <tr>
                <td>Monthly Service Charge on EUR account</td>
                <td>
                  <span>€9.99</span>
                </td>
              </tr>

              <tr>
                <td></td>
                <td>
                  <span></span>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <span></span>
                </td>
              </tr>
              <tr>
                <td class="pt-4"><strong>Account Pricing</strong></td>
                <td>
                  <span></span>
                </td>
              </tr>
              <tr>
                <td>Outgoing Bank Transfer (UK Local account)</td>
                <td>
                  <span>£0.99</span>
                </td>
              </tr>
              <tr>
                <td>Incoming Bank Transfer (UK Local account)</td>
                <td>
                  <span>£0.79</span>
                </td>
              </tr>
              <tr>
                <td>Outgoing Bank Transfer (EU SEPA)</td>
                <td>
                  <span>€0.99</span>
                </td>
              </tr>
              <tr>
                <td>Incoming Bank Transfer (EU SEPA)</td>
                <td>
                  <span>€0.89</span>
                </td>
              </tr>
    
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td class="pt-4">
                  <strong>International Payment (SWIFT PAYMENT)</strong>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Incoming in GBP via Swift international</td>
                <td>
                  <span>£15.00</span>
                </td>
              </tr>
              <tr>
                <td>Outgoing in GBP via Swift international</td>
                <td>
                  <span>£25.00</span>
                </td>
              </tr>
              <tr>
                <td>Incoming in EUR via Swift international</td>
                <td>
                  <span>€20.00</span>
                </td>
              </tr>
              <tr>
                <td>Outgoing in EUR via Swift international</td>
                <td>
                  <span>€35.00</span>
                </td>
              </tr>

              <tr>
                <td></td>
                <td>
                  <span></span>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>          
            </table>
            <table style="width:100%" v-if="isFeatureCard">
              <tr>
                <td class="pt-4"><strong>Card Pricing</strong></td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>Card Fee</td>
                <td>
                  <span>£10.00</span>
                </td>
              </tr>
              <tr>
                <td>Lost/ Stolen Replacement Card</td>
                <td>
                  <span>£10.00</span>
                </td>
              </tr>
              <tr>
                <td>
                  ATM withdraw domestic up to £250 per time / 4 time a day
                </td>
                <td>
                  <span>£3.00</span>
                </td>
              </tr>
              <tr>
                <td>ATM withdrawal international</td>
                <td>
                  <span>£10.00</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span style="color: #ff6600"
                    >Debit/Credit Card deposit to account</span
                  >
                </td>
                <td>
                  <span style="color: #3366ff"
                    >&nbsp;<span style="color: #ff6600"
                      >Minimum £1 or base on volume GBP 0.50% / 1.00%</span
                    ></span
                  >
                </td>
              </tr>
              <tr>
                <td>Instant bank transfer between A&amp;B Money account</td>
                <td>
                  <span>£0.50</span>
                </td>
              </tr>
              <tr>
                <td>Manual debit/credit (By Bank Staff)</td>
                <td>
                  <span>Free</span>
                </td>
              </tr>
              <tr>
                <td>ATM Balance Inquiry</td>
                <td>
                  <span>£0.99</span>
                </td>
              </tr>
              <tr>
                <td>ATM PIN change</td>
                <td>
                  <span>£0.99</span>
                </td>
              </tr>
              <tr>
                <td>POS domestic</td>
                <td>
                  <span>£0.50</span>
                </td>
              </tr>
              <tr>
                <td>POS international</td>
                <td>
                  <span>GBP 1.00 plus 1.00%</span>
                </td>
              </tr>
              <tr>
                <td>POS/ATM Decline</td>
                <td>
                  <span>£0.50</span>
                </td>
              </tr>
              <tr>
                <td>PIN Reveal</td>
                <td>
                  <span>£2.00</span>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            </table>

            <table style="width:100%">
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <span
                    ><strong>FX Fee (Between the account)</strong></span
                  >
                </td>
                <td style="text-align: center; width: 203.5px">
                  <span style="color: #3366ff">&nbsp;</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span
                    >FX margins from 40 basis points (0.40%) depend on
                    trade volume</span
                  >
                </td>
                <td style="text-align: center; width: 203.5px">
                  <span style="color: #3366ff"></span>
                </td>
              </tr>
            </table>
            <p class="text-xs">
              <b>Please note:</b> Online Banking will be lock if system unable to redeem System Monthly Service
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components:{
     isFeatureCard(){
      return this.$store.getters.feature.card
    },
  }
};
</script>

<style lang="scss">
.price-tariff {
  table {
    font-size: 0.8rem;
    tr {
      td:nth-child(2) {
        text-align: right;
        color: #0091ff;
      }
      &:hover{
        background-color: #dcdcdc;
      }
    }
  }
}
</style>