<template>
  <div>
    <price-tariff-personal v-if="!isBusiness"/>
    <price-tariff-business v-if="isBusiness"/>
  </div>
</template>

<script>
import PriceTariffPersonal from './PriceTariffPersonal.vue'
import PriceTariffBusiness from './PriceTariffBusiness.vue'
export default {
  components:{
    PriceTariffPersonal,
    PriceTariffBusiness
  },
  computed: {
    isBusiness(){
      return this.$store.getters.business
    }
  },
}
</script>

<style>

</style>